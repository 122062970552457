import React from "react"
import Layout from "../../../components/Layout"
import Status from "../../../components/Status"
import WrapperView from "../../../components/WrapperView";

const FaqPage = (props) => (
    <Layout  {...props}>
    <Status auth={props.auth}  user={props.user}  />
    <WrapperView title="Can I ship certificates inside my native app?">
    <div>
        <h1 className="text-xl font-bold"><u style={{borderBottom: "double"}}>NO!</u></h1>
    </div>
    <div className="mt-4">
        The reason why we generate only short-term (with 1-3 months expiration period) certificates that you don't even think about putting these into production in any form.
    </div>
    <div className="mt-4">
        <b>RedirectTo.Me is a tool to make your life easier <u>during development</u> on localhost or in local network environments.</b>
    </div>
    <div className="mt-4">
        Let's Encrypt <a className="link" href="https://letsencrypt.org/docs/certificates-for-localhost/#for-native-apps-talking-to-web-apps">summarized it very well</a>, so read their words:
    </div>
    <blockquote className="p-4 my-4 border-l-4 border-gray-300 bg-gray-50">
    <svg aria-hidden="true" class="w-10 h-10 text-gray-400" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/></svg>
    <p className="mt-4">
        Sometimes developers want to offer a downloadable native app that can be
        used alongside a web site to offer extra features. For instance, the Dropbox
        and Spotify desktop apps scan for files from across your machine, which a
        web app would not be allowed to do. One common approach is for these native
        apps to offer a web service on localhost, and have the web app make requests
        to it via XMLHTTPRequest (XHR) or WebSockets. The web app almost always uses HTTPS, which
        means that browsers will forbid it from making XHR or WebSockets requests
        to non-secure URLs. This is called Mixed Content Blocking. To communicate with
        the web app, the native app needs to provide a secure web service.
    </p>
    <p className="mt-4">
        Fortunately, modern browsers <a className="link" href="https://bugs.chromium.org/p/chromium/issues/detail?id=607878">consider</a> <code>http://127.0.0.1:8000/</code> to be a
        <a className="link" href="https://www.w3.org/TR/secure-contexts/#is-origin-trustworthy">“potentially trustworthy”</a>
        URL because it refers to a loopback address. Traffic sent to <code>127.0.0.1</code> is guaranteed
        not to leave your machine, and so is considered automatically secure against
        network interception. That means if your web app is HTTPS, and you offer a
        native app web service on <code>127.0.0.1</code>, the two can happily communicate via XHR.
        Unfortunately, <a className="link" href="https://tools.ietf.org/html/draft-ietf-dnsop-let-localhost-be-localhost-02">localhost doesn’t yet get the same treatment</a>.
        Also, WebSockets don’t get this treatment for either name...
    </p>
    <p className="mt-4">
        ...By introducing a domain name instead of an IP address, you make it possible for
        an attacker to Man in the Middle (MitM) the DNS lookup and inject a response that
        points to a different IP address. The attacker can then pretend to be the local
        app and send fake responses back to the web app, which may compromise your
        account on the web app side, depending on how it is designed.
    </p>
    <p className="mt-4">
        The successful MitM in this situation is possible because in order to make it
        work, you had to ship the private key to your certificate with your native app.
        That means that anybody who downloads your native app gets a copy of
        the private key, including the attacker. This is considered a compromise of your
        private key, and your Certificate Authority (CA) is required to revoke your
        certificate if they become aware of it. <a className="link" href="https://groups.google.com/d/msg/mozilla.dev.security.policy/eV89JXcsBC0/wsj5zpbbAQAJ">Many native apps</a> have <a href="https://groups.google.com/d/msg/mozilla.dev.security.policy/T6emeoE-lCU/-k-A2dEdAQAJ">had their
        certificates</a> revoked for <a className="link" href="https://groups.google.com/d/msg/mozilla.dev.security.policy/pk039T_wPrI/tGnFDFTnCQAJ">shipping their private key</a>.</p>
        <p>Unfortunately, this leaves native apps without a lot of good, secure options to
        communicate with their corresponding web site. And the situation may get
        trickier in the future if browsers further <a className="link" href="https://bugs.chromium.org/p/chromium/issues/detail?id=378566">tighten access to localhost from the
        web</a>.
    </p>
    <p className="mt-4">
        Also note that exporting a web service that offers privileged native APIs is
        inherently risky, because web sites that you didn’t intend to authorize may
        access them. If you go down this route, make sure to read up on <a className="link" href="https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS">Cross-Origin
        Resource Sharing</a>, use Access-Control-Allow-Origin, and make sure to use a
        memory-safe HTTP parser, because even origins you don’t allow access to can send
        preflight requests, which may be able to exploit bugs in your parser.
    </p>
    </blockquote>
    
    </WrapperView>
    </Layout>
)

export default FaqPage

export const Head = ({ location, params, data, pageContext }) => {
    return (
    <>
      <title>Can I ship certificates inside my native app?</title>
      <meta name="description" content="Can I ship certificates inside my native app?" />
      <meta name="keywords" content="security, ssl, tls, certificate, native, app, trust, localhost" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://redirectto.me${location.pathname}`} />
        <meta property="og:title" content="Can I ship certificates inside my native app" />
        <meta property="og:description" content="Can I ship certificates inside my native app?" />

        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content={`https://redirectto.me${location.pathname}`} />
        <meta property="twitter:title" content="Can I ship certificates inside my native app?" />
        <meta property="twitter:description" content="Can I ship certificates inside my native app?" />
    </>
    )
  }